import 'mapbox-gl/dist/mapbox-gl.css';
import {
	ActivityIndicator,
	Button,
	CheckboxGroup,
	Container,
	Dialog,
	DialogContent,
	DropdownMenu,
	DropdownMenuCheckboxItem,
	DropdownMenuContent,
	DropdownMenuTrigger,
	HorizontalRule,
	Label,
	Section,
} from '@troon/ui';
import { Title } from '@solidjs/meta';
import { createEffect, createRenderEffect, createSignal, For, Suspense, Switch, Match, Show } from 'solid-js';
import { createAsync } from '@solidjs/router';
import { WindowVirtualizer } from 'virtua/solid';
import { createIntersectionObserver } from '@solid-primitives/intersection-observer';
import { IconFilter } from '@troon/icons/filter';
import { IconArrowUpMd } from '@troon/icons/arrow-up-md';
import { produce } from 'solid-js/store';
import { isServer } from 'solid-js/web';
import { useWindowScrollPosition } from '@solid-primitives/scroll';
import { SearchLocations } from '../../components/search-locations';
import { createSearchStore } from '../../modules/search-store';
import { Grid, GridFive, GridSeven } from '../../components/layouts/grid';
import { FacilityBrand, gql } from '../../graphql';
import { cachedQuery } from '../../graphql/cached-get';
import { courseTypeString } from '../../components/facility/type-strings';
import { Map } from '../../components/facility-search/map';
import { FacilityCard } from '../../components/facility-search/card';
import { schema } from '../../components/facility-search/schema';
import { FacilitySearchBlankData } from '../../components/facility-search/blank-data';
import type { RouteDefinition } from '@solidjs/router';
import type { FacilityCardFragment } from '../../components/facility-search/card';
import type { FragmentType, FacilityType } from '../../graphql';
import type { MapHandler } from '../../components/facility-search/map';
import type { SearchStore, SetSearchStore } from '../../modules/search-store';
import type { WindowVirtualizerHandle } from 'virtua/solid';
import type { z } from 'zod';

const SCROLL_OFFSET = -155; // magic number: ~ 1rem * 10 to account for header

const sessionKeyPrefix = 'fa-scroll-index';

export default function FacilitySearch() {
	const [search, setSearch] = createSearchStore(schema);
	const [virtualizer, setVirtualizer] = createSignal<WindowVirtualizerHandle>();
	const [mapRef, setMapRef] = createSignal<MapHandler>();
	const [targets, setTargets] = createSignal<Array<HTMLElement>>([]);
	const [mapExpanded, setMapExpanded] = createSignal(false);
	const [focused, setFocused] = createSignal<FragmentType<typeof FacilityCardFragment>>();
	const [highlighted, setHighlighted] = createSignal<Array<Element>>([]);
	const scroll = useWindowScrollPosition();

	createIntersectionObserver(
		targets,
		(entries) => {
			if (window.scrollY < Math.abs(SCROLL_OFFSET)) {
				return;
			}
			setHighlighted((s) => {
				for (const el of entries) {
					const idx = s.indexOf(el.target);
					if (!el.isIntersecting && idx > -1) {
						s.splice(idx, 1);
					}
					if (el.isIntersecting && idx === -1) {
						s.push(el.target);
					}
				}
				return [...s];
			});
		},
		{ threshold: 0.7, rootMargin: '-50px 0px -25% 0px' },
	);

	createEffect(() => {
		const els = highlighted();
		const handler = mapRef();
		if (handler) {
			if (els.length) {
				const slug = els[0]!.getAttribute('data-id');
				const facility = data()?.facilities.facilities.find((f) => f.slug === slug);

				if (facility && handler) {
					handler.setFocus(facility);
				}
			}
		}
	});

	createEffect(() => {
		if (mapExpanded()) {
			setFocused();
			setHighlighted([]);
		}
	});

	const data = createAsync(
		async () => {
			const query = {
				regionIds: search.regionId ? [search.regionId] : null,
				location:
					search.lat && search.lon ? { latitude: search.lat, longitude: search.lon, radiusMiles: search.radius } : null,
				access: search.access ?? undefined,
				rewards: search.rewards ?? undefined,
				brands: search.brands?.length ? search.brands : null,
				types: search.types?.length ? search.types : null,
			} satisfies Parameters<typeof getFacilities>[0];

			if (!query.regionIds && !query.location) {
				return null;
			}
			const data = await getFacilities(query);
			setFocused();
			setHighlighted([]);
			return data;
		},
		{ deferStream: true },
	);

	createEffect(() => {
		if (data() && !isServer) {
			window.scrollTo({ top: 0 });
		}
	});

	createRenderEffect(() => {
		try {
			const scrollY = parseInt(sessionStorage.getItem(`${sessionKeyPrefix}-${location.pathname}`) ?? '');
			if (data.latest?.facilities.totalCount && virtualizer() && !isNaN(scrollY)) {
				virtualizer()?.scrollToIndex(scrollY, { offset: SCROLL_OFFSET });
				sessionStorage.removeItem(`${sessionKeyPrefix}-${location.pathname}`);
			}
		} catch {
			// ignore browsers disallowing session access
		}
	});

	return (
		<>
			<Title>Find golf courses | Troon</Title>
			<div class="sticky top-0 z-20 max-w-full border-b border-neutral bg-white">
				<Container class="flex flex-row gap-4 overflow-hidden py-4 md:py-8 lg:gap-6">
					<SearchLocations
						onSelectPlace={(place) => {
							setSearch({
								query: place.displayValue,
								regionId: null,
								lat: place.coordinates.latitude,
								lon: place.coordinates.longitude,
							});
						}}
						onSelectRegion={(region) => {
							setSearch({ query: region.displayValue, lat: null, lon: null, regionId: region.id });
						}}
						defaultValue={search.query as string}
						inputClass="ps-10 py-4"
						mobileInputClass="ps-3 border border-neutral py-4"
						placeholder="Search by city, state, or course name"
					/>
					<SearchFilters filters={search} setFilters={setSearch} />
				</Container>
			</div>
			<div class="relative -mb-12 min-h-dvh bg-neutral-100 sm:py-4 lg:py-8">
				<Container class="px-0">
					<Grid>
						<GridFive class="z-10 lg:order-2" classList={{ 'absolute inset-0': mapExpanded() }}>
							{/* Would prefer classList here, but it's not applying correctly after navigation? */}
							<Section class={!mapExpanded() ? 'top-16 lg:sticky lg:top-40' : undefined}>
								<div
									// eslint-disable-next-line tailwindcss/no-arbitrary-value
									class="relative h-48 overflow-hidden lg:h-[calc(100dvh-12rem)]"
									classList={{
										'sm:rounded': !mapExpanded(),
										'absolute inset-0 h-[calc(100dvh-9.6rem)]': mapExpanded(),
									}}
								>
									<Suspense>
										<Map
											ref={setMapRef}
											facilities={data.latest?.facilities.facilities ?? []}
											latitude={search.lat}
											longitude={search.lon}
											onExpand={setMapExpanded}
											onSelectFacility={(facility) => {
												const index = data()?.facilities.facilities.findIndex((f) => f.slug === facility.slug);
												virtualizer()?.scrollToIndex(index ?? 0, {
													smooth: true,
													offset: SCROLL_OFFSET,
												});
												const handler = mapRef();
												if (typeof index !== 'undefined' && index > -1 && facility && handler) {
													setFocused(data()!.facilities.facilities[index]);
													!mapExpanded() && handler.setFocus(facility);
												}
											}}
											onSearchBounds={(center, dist) => {
												setSearch({ regionId: null, lat: center.lat, lon: center.lng, radius: dist, query: null });
											}}
										/>
									</Suspense>
								</div>
							</Section>
						</GridFive>
						<Switch>
							<Match when={!mapExpanded()}>
								<GridSeven class="relative z-0">
									<Suspense
										fallback={
											<div class="min-h-dvh">
												<ActivityIndicator />
											</div>
										}
									>
										<Show
											when={data()?.facilities.facilities.length}
											fallback={<FacilitySearchBlankData filters={search} setFilters={setSearch} />}
										>
											{(count) => (
												<>
													<div
														class="pointer-events-none sticky inset-x-auto top-28 z-30 -mb-12 grid place-content-center transition-all delay-1000 duration-300 lg:top-36"
														classList={{
															'-translate-y-32': scroll.y <= Math.abs(SCROLL_OFFSET),
															'translate-y-0': scroll.y > Math.abs(SCROLL_OFFSET),
														}}
													>
														<Button
															appearance="tertiary"
															class="pointer-events-auto shadow"
															onClick={() => {
																window.scrollTo({ top: 0, behavior: 'smooth' });
															}}
														>
															<IconArrowUpMd />
															Back to top
														</Button>
													</div>
													<p class="px-4 pb-8 font-medium text-neutral-700 lg:px-0">Showing {count()} locations</p>
													<WindowVirtualizer
														overscan={3}
														ref={setVirtualizer}
														data={data.latest?.facilities.facilities || []}
														itemSize={600}
													>
														{(facility, index) => (
															<div
																ref={(el) => setTargets((t) => [...t, el])}
																data-id={facility.slug}
																class="px-4 pb-16 lg:px-0"
																onClick={() => {
																	sessionStorage.setItem(`${sessionKeyPrefix}-${location.pathname}`, `${index}`);
																}}
															>
																<FacilityCard facility={facility} />
															</div>
														)}
													</WindowVirtualizer>
													<div class="h-dvh" />
												</>
											)}
										</Show>
									</Suspense>
								</GridSeven>
							</Match>
							<Match when={mapExpanded() && focused()}>
								{(focused) => (
									<Dialog
										open
										key="course-search"
										onOpenChange={(open) => {
											if (!open) {
												setFocused(undefined);
											}
										}}
									>
										<DialogContent height="fit" header="Course details" headerLevel="h2">
											<FacilityCard facility={focused()} />
										</DialogContent>
									</Dialog>
								)}
							</Match>
						</Switch>
					</Grid>
				</Container>
			</div>
		</>
	);
}

const query = gql(`query courseDirectorySearch(
	$regionIds: [String!]
	$location: FacilityLocationInput
	$macroRegionIds: [String!]
	$types: [FacilityType!]
	$brands: [FacilityBrand!]
	$access: Boolean
	$rewards: Boolean
) {
	facilities: facilitiesV3(
		location: $location
		regionIds: $regionIds
		macroRegionIds: $macroRegionIds
		types: $types
		brands: $brands
		supportsTroonAccess: $access
		supportsTroonRewards: $rewards
		sortBy: name
	) {
		facilities {
			slug
			...FacilityMapInfo
			...FacilityCardFragment
		}
		totalCount
	}
}`);

const getFacilities = cachedQuery(query);

type SearchFiltersProps = {
	filters: SearchStore<typeof schema>;
	setFilters: SetSearchStore<typeof schema>;
};

function SearchFilters(props: SearchFiltersProps) {
	return (
		<DropdownMenu placement="bottom-end">
			<DropdownMenuTrigger appearance="tertiary-current" class="grow-0 p-4">
				<IconFilter class="size-6" />
				<span class="sr-only">Filter results</span>
			</DropdownMenuTrigger>
			<DropdownMenuContent>
				<CheckboxGroup name="type">
					<Label class="px-2 font-semibold">Facility type</Label>
					<For each={Object.entries(courseTypeString) as Array<[FacilityType, string]>}>
						{([key, label]) => (
							<DropdownMenuCheckboxItem
								value={key}
								checked={props.filters.types?.includes(key)}
								onChange={(checked) => {
									props.setFilters(
										produce((s) => {
											s.types = Array.isArray(s.types) ? s.types : s.types ? [s.types] : [];
											const idx = s.types.indexOf(key);
											if (idx >= 0 && !checked) {
												s.types.splice(idx, 1);
											}
											if (idx < 0 && checked) {
												s.types.push(key);
											}
										}),
									);
								}}
							>
								{label}
							</DropdownMenuCheckboxItem>
						)}
					</For>
				</CheckboxGroup>

				<HorizontalRule />

				<CheckboxGroup name="programs">
					<Label class="px-2 font-semibold">Troon programs</Label>
					<For each={Object.entries(programs)}>
						{([key, label]) => (
							<DropdownMenuCheckboxItem
								value={key}
								checked={!!props.filters[key as keyof z.infer<typeof schema>]}
								onChange={(checked) => {
									props.setFilters({ [key]: checked ? true : null });
								}}
							>
								{label}
							</DropdownMenuCheckboxItem>
						)}
					</For>
				</CheckboxGroup>

				<HorizontalRule />

				<CheckboxGroup name="brand">
					<Label class="px-2 font-semibold">Brand</Label>
					<For each={Object.values(FacilityBrand)}>
						{(brand) => (
							<DropdownMenuCheckboxItem
								value={brand}
								checked={props.filters.brands?.includes(brand)}
								onChange={(checked) => {
									props.setFilters(
										produce((s) => {
											s.brands = Array.isArray(s.brands) ? s.brands : s.brands ? [s.brands] : [];
											const idx = s.brands.indexOf(brand);
											if (idx >= 0 && !checked) {
												s.brands.splice(idx, 1);
											}
											if (idx < 0 && checked) {
												s.brands.push(brand);
											}
										}),
									);
								}}
							>
								{brandString[brand]}
							</DropdownMenuCheckboxItem>
						)}
					</For>
				</CheckboxGroup>
			</DropdownMenuContent>
		</DropdownMenu>
	);
}

const brandString: Record<FacilityBrand, string> = {
	[FacilityBrand.ObSports]: 'OBSports',
	[FacilityBrand.TroonGolf]: 'Troon Golf',
	[FacilityBrand.TroonPrive]: 'Troon Privé',
	[FacilityBrand.Icon]: 'Icon',
	[FacilityBrand.IndigoSports]: 'Indigo Sports',
	[FacilityBrand.TroonGolfIntl]: 'Troon Golf International',
	[FacilityBrand.TroonPartner]: 'Troon Partner',
	[FacilityBrand.TroonPriveIntl]: 'Troon Privé International',
	[FacilityBrand.None]: 'Others',
};

const programs: Record<'access' | 'rewards', string> = {
	access: 'Troon Access',
	rewards: 'Troon Rewards',
};

export const route = {
	info: {
		nav: { sticky: false },
		// The map view requires knowing the height of the available space
		// The nav and search bars are known heights, but the banner is an unknown
		// So we need to not have it on the search pages
		banner: { hide: true },
	},
} satisfies RouteDefinition;
